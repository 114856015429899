import React/*, { useState } */ from 'react';
import Pagination from '../Paginations/Pagination';
import './Table.css';

import green from '../../assets/icons/circle-green.svg'
import red from '../../assets/icons/circle-red.svg'
import grey from '../../assets/icons/circle-grey.svg'
import Edit from '../../assets/icons/edit_icon.svg'
import Trash from '../../assets/icons/trash.svg'

import MenuIcon from '../Menus/MenuIcon/MenuIcon';


function Table({ headers, data, styles, currentPage, setCurrentPage, totalPages, menuIcon, isMenu, link, onSelect, filaSeleccionada, addInfo, tipo, from, handleDetailsShow, showImproper, menuIcon2 }) {
    //console.log(data);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const redirectToPage = (id = '') => {
        // Redirige a la página deseada al hacer clic en la fila
        if (link != null) {
            window.location.href = link + id;
        }
    };

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    //en card, td, onClick... quisiera algo asi:
    // () => ((item.estatus === 'ACTIVE') || (item.estatus === 'BLOCKED')) ? menuIcon : null 
    // para no "mostrar" la modal, si el estatus es CANCELADA o EMBOSADA... 
    return (
        <div>
            <div className="bg-table">
                <table>
                    <thead className="headtable">
                        <tr className="trHead">
                            {headers.map((header, index) => (
                                <th className="thtable" key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    {(() => {
                        switch (from) {
                            case 'udns':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(item) : null}>
                                            {Object.values(item).slice(0, headers ? headers.length - 1 : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={() => redirectToPage(item.idUdn)} className={styles[subIndex]}>
                                                    {(styles[subIndex] === "amountStyle") ? dollarUS.format(value) : value}
                                                </td>
                                            ))}
                                            {isMenu ? (
                                                <td>
                                                    <MenuIcon menuIcon={menuIcon} tipo={tipo} />
                                                </td>
                                            ) : (
                                                null
                                            )}
                                        </tr>
                                    ))}
                                </tbody>;
                            case 'admin':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(item) : null}>
                                            {Object.values(item).slice(0, headers ? headers.length - 1 : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={() => redirectToPage(item.id)} className={styles[subIndex]}>
                                                    {(styles[subIndex] === "amountStyle") ? dollarUS.format(value) : value}
                                                </td>
                                            ))}
                                            {isMenu ? (
                                                <td>
                                                    <MenuIcon id={item.id} menuIcon={menuIcon} tipo={tipo} />
                                                </td>
                                            ) : (
                                                null
                                            )}
                                        </tr>
                                    ))}
                                </tbody>;
                            case 'adminusr':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(item) : null}>
                                            {Object.values(item).slice(0, headers ? headers.length - 1 : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={() => redirectToPage(item.id)} className={styles[subIndex]}>
                                                    {(styles[subIndex] === "amountStyle") ? dollarUS.format(value) : value}
                                                </td>
                                            ))}
                                            {isMenu ? (
                                                <td>
                                                {
                                                    item.idRole != 80 ?
                                                     (<MenuIcon id={item.id} menuIcon={menuIcon} tipo={tipo} />)
                                                     : (<MenuIcon id={item.id} menuIcon={menuIcon2} tipo={tipo} />)
                                                }
                                                </td>
                                            ) : (
                                                null
                                            )}
                                        </tr>
                                    ))}
                                </tbody>;
                            case 'card':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(item) : null}>
                                            {Object.values(item).slice(0, headers ? headers.length - 1 : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={() => redirectToPage(item.id)} className={styles[subIndex]}>
                                                    {(styles[subIndex] === "amountStyle") ? dollarUS.format(value) : value}
                                                </td>
                                            ))}
                                            <td onClick={() => ((item.estatus === 'ACTIVE') || (item.estatus === 'BLOCKED')) ? menuIcon() : null}>
                                                {item.estatus === 'ACTIVE' ? (
                                                    <img src={green} alt="" className='iconButton' />
                                                ) : item.estatus === 'BLOCKED' ? (
                                                    <img src={red} alt="" className='iconButton' />
                                                ) : (<img src={grey} alt="" className='iconButton' />)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>;
                            case 'card2':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(item) : null}>
                                            {Object.values(item).slice(0, headers ? headers.length : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={() => redirectToPage(item.id)} className={styles[subIndex]}>
                                                    {value}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>;
                            case 'myCard':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(item) : null}>
                                            {Object.values(item).slice(0, headers ? headers.length : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={() => redirectToPage(item.id)} className={styles[subIndex]}>
                                                    {(styles[subIndex] === "amountStyle") ? dollarUS.format(value) : value}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>;
                            case 'myCards':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className={filaSeleccionada === index ? 'activetrCard' : 'trtableCard'} key={index} onClick={() => onSelect != null ? onSelect(item, index) : null}>
                                            {Object.values(item).map((value, subIndex) => (
                                                subIndex === 0 ? (
                                                    <td key={subIndex} onClick={() => redirectToPage(item.id)} className={item[6]}>
                                                        {value}
                                                        <div className='row'>
                                                            <div className='col-8'>{item[4]}</div>
                                                            <div className='col-4'>{item[5]}</div> 
                                                        </div>
                                                    </td>
                                                ) : ((subIndex === 1) || (subIndex === 2)) ? (
                                                    <td key={subIndex} className={styles[subIndex]}>
                                                        <div className='col-6'>{(styles[subIndex] === "amountStyle") ? dollarUS.format(value) : value}</div>
                                                    </td>
                                                ) : (null)
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>;
                            case 'myCards2':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className={(item.statusCode > 0 ? 'trStatus' : (item.monto < 0 ? 'trtable' : 'trStatusG'))} key={index} onClick={() => onSelect != null ? onSelect(item) : null}>
                                            {Object.values(item).slice(0, headers ? headers.length : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={() => redirectToPage(item.id)} className={styles[subIndex]}>
                                                    {(styles[subIndex] === "amountStyle") ? dollarUS.format(value) : value}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>;
                            case 'multiuser':
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(item) : null} >
                                            {Object.values(item).slice(0, headers ? headers.length - 1 : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={redirectToPage} className={styles[subIndex]} >
                                                    {(styles[subIndex] === "amountStyle") ? dollarUS.format(value) : value}
                                                </td>
                                            ))}
                                            {isMenu ? (
                                                <td>
                                                    <MenuIcon menuIcon={menuIcon} tipo={tipo} />
                                                </td>
                                            ) : (
                                                <td>
                                                    <button className='btnMenuTable' onClick={onSelect}>
                                                        <img src={Trash} alt="" className='iconButton' />
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>;
                            // case 'transacciones':
                            //     return <tbody>
                            //         {data.map((item, index) => (
                            //             <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(item) : null}>
                            //                 {Object.values(item).slice(0, headers ? headers.length - 1 : 0).map((value, subIndex) => (
                            //                     <td key={subIndex} onClick={() => redirectToPage(item.id)} className={styles[subIndex]}>
                            //                         {value}
                            //                     </td>
                            //                 ))}
                            //                 {isMenu ? (
                            //                     <td>
                            //                         <li className="nav-item dropdown">
                            //                             <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            //                                 <img src={menuIcon} alt="" className='iconButton' />
                            //                             </a>
                            //                             <ul className="dropdown-menu subMenu">
                            //                                 <li className='item'><a className="sub-menu" href="#" onClick={handleDetailsShow}>Revisar Detalle del Cargo</a></li>
                            //                                 <li className='item'><a className="sub-menu" href="#" onClick={showImproper}>Reclamo de Cargo Indebido</a></li>
                            //                             </ul>
                            //                         </li>
                            //                     </td>
                            //                 ) : (
                            //                     null
                            //                 )}
                            //             </tr>
                            //         ))}
                            //     </tbody>;
                            default:
                                return <tbody>
                                    {data.map((item, index) => (
                                        <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(addInfo ? { index, info: addInfo[index] } : null) : null} >
                                            {Object.values(item).slice(0, headers ? headers.length : 0).map((value, subIndex) => (
                                                <td key={subIndex} onClick={redirectToPage} className={styles[subIndex]} >
                                                    {(styles[subIndex] === "amountStyle") || (subIndex === 4 && headers[4] === "Monto") || (subIndex === 5 && headers[5] === "Monto") ? dollarUS.format(value) : value}
                                                </td>
                                            ))}
                                            {isMenu ? (
                                                <td>
                                                    <MenuIcon menuIcon={menuIcon} tipo={tipo} />
                                                </td>
                                            ) : (
                                                <td>
                                                    <button className='btnMenuTable' onClick={onSelect}>
                                                        <img src={Edit} alt="" className='iconButton' />
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>;
                        }
                    })()}
                </table>
            </div>
            <div className="pagination">
                <Pagination totalPages={totalPages} currentPage={currentPage} paginate={paginate} />
            </div>
        </div>
    )
}

export default Table
