import React from 'react'

import '../../css/body.css'
import '../../css/forms.css'

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';

function InternationalTransfers() {

    const isLogin = true;

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='body'>
                <div className="container-fluid2 position-absolute space-acconts">
                    <div className="containerHome2">
                        <div className="container-general">
                            <div className="card-body">
                                <div className='card-body-container-bg'>
                                    <div className="row contentTitle">
                                        <div className="col-md-9">
                                            <h4 className="subTitle2">Transferencias Internacionales</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body-card">
                                    <iframe src="https://app.efexpay.com/login?organizationID=sitespay" width="100%" height="600px" frameborder="0" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default InternationalTransfers
