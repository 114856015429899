import React, { useState, useEffect } from 'react'
import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations';

import '../../components/Table/Table.css'

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';

import ConfirmTransferCard from '../../components/Modals/ConfirmTransferCard';

function SPCardTransfers() {
    const isLogin = true;

    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    const [confirmDisabled, setConfirmDisabled] = useState(false);
    const [cards, setCards] = useState([]);
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const [fromAccountType, setFromAccountType] = useState('fromSpeiAccount');
    const [toAccountType, setToAccountType] = useState('toOwnTdc');
    const userData = JSON.parse(localStorage.getItem('userData'))

    useEffect(() => {
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        //traemos las cuentas del usuario
        fetchData(process.env.REACT_APP_ACCOUNTS + "?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (response) {
                    setAccounts(response.cuentas)
                } else if (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    }
                }
            });

        // traemos las tarjetas a las que tiene acceso el usuario
        fetchData(process.env.REACT_APP_ADMIN_CARDS + "mycards/?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (response) {
                    /// setCardList(response.cards);
                    //console.log(response);
                    setCards(response.cards);
                    /// aqui debemos settear las tarjetas al "select"
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        console.log(error);
                        setAlert({ tipo: 'danger', msn: 'Error en el systema', show: true });
                    }
                    return;
                }
            });

    }, [navigate]);

    const [transferData, setTransferData] = useState({
        trxType: 0,
        origin: '',
        originCard: '',
        destination: '',
        last_four: '',
        amount: '',
        reference: '',
        opsPass: '',
        email: '',
        phoneTo: ''
    });
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => { setShowModal(false); setConfirmDisabled(false); }
    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleRadioButtons = (e) => {
        var { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        if (id === 'fromAccountType' || id === 'fromAccountType1') {
            setFromAccountType(value);
            setTransferData((prevData) => ({
                ...prevData,
                origin: '',
                originCard: ''
            }))
        }
        if (id === 'toAccountType' || id === 'toAccountType1') {
            setToAccountType(value);
            setTransferData((prevData) => ({
                ...prevData,
                destination: '',
                last_four: '',
                phoneTo: ''
            }))
        }
    }

    const handleChange = (e) => {
        var { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        if (id === 'amount' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = transferData[id] || '';
            return;
        }
        if (id === 'reference' && value !== '' && errorIn.integer(value)) {
            e.target.value = transferData[id] || '';
            return;
        }
        if ((id === 'originFromSpei' || id === 'originOwnTdc') && value !== '') {
            if (id === 'originOwnTdc') {
                var originCard = e.target.options[e.target.selectedIndex].label;
                originCard = originCard.toString().substring(0, 19);
                setTransferData((prevData) => ({
                    ...prevData,
                    originCard: originCard
                }))
            }
            id = 'origin'
        }
        if (id === 'destinationOwnTdc' && value !== '') {
            var cardLabel = e.target.options[e.target.selectedIndex].label;
            cardLabel = cardLabel.toString().substring(0, 19);
            setTransferData((prevData) => ({
                ...prevData,
                last_four: cardLabel
            }))
            id = 'destination';
        }

        //TODO: Ver porque no jala el de longitud en los siguientes dos casos ???
        if (id === 'last_four' && value !== '' && errorIn.integer(value) && errorIn.length(value, 4, 4, "error")) {
            e.target.value = transferData[id] || '';
            return;
        }
        if (id === 'phoneTo' && value !== '' && errorIn.integer(value) && errorIn.length(value, 10, 10, "error")) {
            e.target.value = transferData[id] || '';
            return;
        }

        setTransferData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };
    const closeAlert = (alert) => {
        setConfirmDisabled(false);
        setAlert(alert);
    };
    const resetTranferData = () => {
        setTransferData({
            origin: '',
            destination: '',
            last_four: '',
            email: '',
            amount: '',
            reference: '',
            phoneTo: ''
        });
    };

    const validateTransactionData = () => {
        setAlert({ tipo: '', msn: '', show: false });

        const errors = [];

        if (fromAccountType === 'fromSpeiAccount' && toAccountType === 'toOwnTdc') {
            setTransferData((prevData) => ({
                ...prevData,
                trxType: 51
            }));
            if (errorIn.length(transferData.origin, 18, 18, "Cuenta origen inválida")) errors.push("Cuenta SPEI inválida");
            if (errorIn.lengthTrim(transferData.destination, 20, 40, "Número de tarjeta inválido")) errors.push("Tarjeta para recarga inválida");
        } else {
            if (fromAccountType === 'fromSpeiAccount' && toAccountType === 'toOthersTdc') {
                setTransferData((prevData) => ({
                    ...prevData,
                    trxType: 52
                }));
                if (errorIn.length(transferData.origin, 18, 18, "Cuenta origen inválida")) errors.push("Cuenta SPEI inválida");
                if (errorIn.lengthTrim(transferData.phoneTo, 10, 10, "Número de teléfono inválido")) errors.push("Número de teléfono inválido");
                if (errorIn.lengthTrim(transferData.last_four, 4, 4, "Número de tarjeta inválido")) errors.push("Tarjeta para recarga inválida");
            } else {
                if (fromAccountType === 'fromOwnTdc' && toAccountType === 'toOwnTdc') {
                    setTransferData((prevData) => ({
                        ...prevData,
                        trxType: 53
                    }));
                    if (errorIn.length(transferData.origin, 20, 40, "Cuenta origen inválida")) errors.push("TDC Propia es inválida");
                    if (errorIn.lengthTrim(transferData.destination, 20, 40, "Número de tarjeta inválido")) errors.push("Tarjeta para recarga inválida");
                    if (transferData.origin === transferData.destination)
                        errors.push("Tarjeta de origen de la recarga no puede ser igual a la tarjeta destino");
                } else {
                    if (fromAccountType === 'fromOwnTdc' && toAccountType === 'toOthersTdc') {
                        setTransferData((prevData) => ({
                            ...prevData,
                            trxType: 54
                        }));
                        if (errorIn.length(transferData.origin, 20, 40, "Cuenta origen inválida")) errors.push("TDC Propia es inválida");
                        if (errorIn.lengthTrim(transferData.phoneTo, 10, 10, "Número de teléfono inválido")) errors.push("Número de teléfono inválido");
                        if (errorIn.lengthTrim(transferData.last_four, 4, 4, "Número de tarjeta inválido")) errors.push("Tarjeta para recarga inválida");
                    } else {
                        setAlert({ tipo: 'danger', msn: 'Opción de recarga incorrecta', show: true });
                        return;
                    }
                }
            }
        }

        if (transferData.email.trim() !== '' && errorIn.email(transferData.email, "Email inválido")) errors.push("Email inválido");
        if (errorIn.money(transferData.amount, "Monto inválido")) errors.push("Monto inválido");
        if (transferData.reference === '' || errorIn.numeric(transferData.reference, "Referencia inválida")) errors.push("Referencia inválida");

        if (errors.length > 0) {
            setAlert({ tipo: 'danger', msn: errors.join('\n'), show: true });
        } else {
            setConfirmDisabled(true);
            setAlert({ tipo: '', msn: '', show: false });
            handleShowModal();
        }
    };

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='body'>
                <div className="container-fluid2 position-absolute space-acconts">
                    <div className="containerHome2">
                        <div className="container-general">
                            <div className="card-body">
                                <div className='card-body-container-bg'>
                                    <div className="row contentTitle">
                                        <div className="col-sm-9">
                                            <h4 className="subTitle2">Pago a Tarjeta SitesPay</h4>
                                        </div>
                                        <div className="col-sm-3 divContainerBtn">
                                            <div className="d-grid">
                                                <button onClick={validateTransactionData} className="btnTraspasos" type="button" disabled={confirmDisabled}>Realizar Traspaso</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body-card">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <div className="row containerRowCard">
                                        <div className="col-md-6 mb-3 mb-sm-0">
                                            <div className="card card-caratula">
                                                <div className="card-body">
                                                    <h5 className="text-3">Datos para el pago a tarjeta</h5>
                                                    <hr></hr>
                                                    <div className="contentDivRow">
                                                        <div className="mb-3">
                                                            <div className='row space-radio'>
                                                                <div className='col-sm-6'>
                                                                    <label className="form-label">
                                                                        <input type="radio" id="fromAccountType" name="fromAccountType" value="fromSpeiAccount" defaultChecked onChange={handleRadioButtons} /> Pagar desde una cuenta clabe
                                                                    </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <label className="form-label">
                                                                        <input type="radio" id="fromAccountType1" name="fromAccountType" value="fromOwnTdc" onChange={handleRadioButtons} /> Pagar desde una tarjeta propia
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {(fromAccountType === 'fromSpeiAccount') &&
                                                                <select className="form-select" id="originFromSpei" value={transferData.origin} onChange={handleChange}>
                                                                    <option value='0'>Seleccione la cuenta clabe</option>
                                                                    {accounts.map((account) => (
                                                                        <option
                                                                            key={account.id}
                                                                            value={account.accountNumber}>
                                                                            {account.accountNumber} - {account.description}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            }
                                                            {(fromAccountType === 'fromOwnTdc') &&
                                                                <select className="form-select" id="originOwnTdc" value={transferData.origin} onChange={handleChange} aria-label="Seleccionar tarjeta">
                                                                    <option value=''>Seleccione la tarjeta a pagar</option>
                                                                    {cards.map((card) => (
                                                                        <option
                                                                            key={card.cardId}
                                                                            label={card.last_four + ' - ' + card.nombre}
                                                                            value={card.cardId}>
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            }
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className='row space-radio'>
                                                                <div className='col-sm-6'>
                                                                    <label className="form-label">
                                                                        <input type="radio" id="toAccountType" name="toAccountType" value="toOwnTdc" defaultChecked onChange={handleRadioButtons} /> Pagar una tarjeta propia
                                                                    </label>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                    <label className="form-label">
                                                                        <input type="radio" id="toAccountType1" name="toAccountType" value="toOthersTdc" onChange={handleRadioButtons} /> Pagar una tarjeta de terceros
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {(toAccountType === 'toOwnTdc') &&
                                                                <select className="form-select" id="destinationOwnTdc" value={transferData.destination} onChange={handleChange} aria-label="Seleccionar tarjeta">
                                                                    <option value=''>Seleccione la tarjeta a pagar</option>
                                                                    {cards.map((card) => (
                                                                        <option
                                                                            key={card.cardId}
                                                                            label={card.last_four + ' - ' + card.nombre}
                                                                            value={card.cardId}>
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            }
                                                            {(toAccountType === 'toOthersTdc') &&
                                                                <div>
                                                                    <div className="mb-3">
                                                                        <input className="form-control" type="text" id="phoneTo" value={transferData.phoneTo} onChange={handleChange} placeholder='Teléfono de terceros' />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <input className="form-control" type="text" id="last_four" value={transferData.last_four} onChange={handleChange} placeholder='Últimos 4 digitos de la tarjeta' />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Correo electrónico (opcional)</label>
                                                            <input type="email" id='email' value={transferData.email} onChange={handleChange} className="form-control" placeholder="ejemplo@gmail.com" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-sm-0">
                                            <div className="card card-caratula">
                                                <div className="card-body">
                                                    <h5 className="text-3">Datos de transferencia</h5>
                                                    <hr></hr>
                                                    <div className="contentDivRow">
                                                        <div className="mb-3">
                                                            <label className="form-label">Monto a Transferir</label>
                                                            <input type="text" id='amount' value={transferData.amount} onChange={handleChange} className="form-control" placeholder="$0.00" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Número de referencia</label>
                                                            <input type="text" id='reference' value={transferData.reference} onChange={handleChange} maxLength='7' className="form-control" placeholder="Número de referencia" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid contentBtn2">
                                        <button onClick={validateTransactionData} className="btnTraspasos" type="button" disabled={confirmDisabled}>Realizar Traspaso</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmTransferCard transferData={transferData} show={showModal} handleClose={handleCloseModal} resetTranferData={resetTranferData} transferType={3} otpRequired={userData.opsOTPRequired} />
            <Footer />
        </div>
    )
}

export default SPCardTransfers
