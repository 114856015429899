const mapping = {
    A: 'Abono o SPEI recibido',
    P: 'Pago o transferencia SPEI',
    C: 'Comisiones pagadas',
    T: 'Pago a Tarjeta',
    D: 'Devolución'
  };
  
  export function getOperationDescription(type) {
    return mapping[type] || '';
  }